<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="isLoading" :disabled="isLoading">
          <v-card-title>
            {{ $t(dialogTitle) }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">

            <v-text-field :label="$t('field.name')"
                          :error-messages="errors.name"
                          class="required"
                          v-model="form.name">
            </v-text-field>

          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :loading="isLoading"
                   type="submit"
                   depressed>
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('btn.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data: () => ({
    dialogTitle: 'database.dialog.renameDatabase',
    isLoading: false,
    dialog: false,
    form: {},
    errors: {},
  }),
  methods: {
    open(item) {
      this.form = {
        id: item.id,
        name: item.name,
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "databases/rename"

      HTTP.post(url, this.form).then(() => {
        this.$Progress.finish()
        this.$emit('refresh')
        this.isLoading = false
        this.close()
        this.$successMessage = this.$i18n.t('message.succssStore')
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  }
}
</script>