<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-overlay :value="overlay"
                 absolute
                 :color="$vuetify.theme.dark ?'secondary':'white'">
        <v-progress-circular
            indeterminate
            size="60"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="3">
          <v-card class="rounded-lg shadow" height="330">
            <v-card-text>

              <v-text-field rounded filled dense
                            class="mb-5"
                            v-model="search"
                            append-icon="mdi-magnify"
                            single-line hide-details clearable
                            :placeholder="$t('field.search')"/>

              <v-btn color="primary"
                     block large
                     depressed
                     @click="newDatabase">
                <v-icon left>mdi-plus</v-icon>
                {{ $t('btn.add') }}
              </v-btn>

              <v-btn color="primary"
                     text
                     class="mt-4 v-btn--active"
                     to="/databases/config"
                     block
                     large
                     depressed>
                <v-icon left>mdi-cog</v-icon>
                {{ $t('breadcrumb.config') }}
              </v-btn>
              <v-alert color="info" text dense class="mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <span @click="downlaodCanva" class="text-decoration-underline cursor-pointer">
                  {{ $t('btn.download') }}
                </span>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="9">

          <v-row v-if="isLoading">
            <v-col cols="4" v-for="el in 6" :key="el">
              <v-card class="rounded-lg shadow">
                <v-card-text>
                  <v-skeleton-loader type="card"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-else>

            <v-col cols="12" v-if="!databases.length">
              <v-card class="rounded-lg shadow">
                <v-card-text class="pa-0">
                  <NoResult size="200"/>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col v-else cols="4" v-for="(database,i) in databases" :key="i">
              <v-card class="rounded-lg shadow" height="330">
                <v-card-title>
                  <SwitchStatus @refresh="getDatabases" @overlay="overlay = $event"
                                :label="$t('field.isActive')"
                                :id="database.id" url="databases/change-status" :value="database.is_active"/>
                  <v-spacer/>

                  <v-btn text small color="primary"
                         @click="showDatabase(database.id)"
                         class="text-decoration-underline">
                    {{ $t('btn.show') }}
                  </v-btn>

                </v-card-title>
                <div class="text-center">
                  <v-avatar tile size="110">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                </div>
                <v-card-title class="fs-16">{{ database.name }}</v-card-title>
                <v-card-subtitle>
                  <v-icon dense left color="primary">mdi-clock-outline</v-icon>
                  {{ database.created_at }}
                  <v-spacer/>
                  <v-icon dense left color="primary">mdi-home-group</v-icon>
                  {{ database.salepoints_count }}
                </v-card-subtitle>

                <v-divider/>

                <v-card-actions>
                  <v-spacer/>
                  <Export :link="'databases/'+database.id+'/export'" color="blue"/>
                  <Update :rename="true" @update="renameDatabase(database)"/>
                  <Delete :id="database.id" url="databases/delete" @overlay="overlay=$event" @refresh="getDatabases"/>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

        </v-col>

      </v-row>

      <NewDatabaseDialog ref="newDatabaseDialog" @refresh="getDatabases"/>
      <RenameDatabaseDialog ref="renameDatabaseDialog" @refresh="getDatabases"/>

    </v-container>
  </div>
</template>

<script>


import {HTTP} from "@/http-common";
import NewDatabaseDialog from "@/views/databases/components/NewDatabaseDialog";
import RenameDatabaseDialog from "@/views/databases/components/RenameDatabaseDialog";

export default {
  components: {RenameDatabaseDialog, NewDatabaseDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      search: null,
      databases: []
    }
  },
  methods: {
    getDatabases() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/databases').then(res => {
        this.databases = res.data.data
        this.$store.commit('api/databases', this.databases)
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    newDatabase() {
      this.$refs.newDatabaseDialog.open()
    },
    showDatabase(id) {
      this.$router.push('/databases/' + id + '/show')
    },
    downlaodCanva() {
      window.location.href = process.env.VUE_APP_FILE_URL + '/canva_opus.xlsx';
    },
    renameDatabase(item) {
      this.$refs.renameDatabaseDialog.open(item)
    },
  },
  created() {
    this.getDatabases()
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/databases', text: 'breadcrumb.databases'},
      ]
    },
  }
}
</script>

<style scoped>
</style>