<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="isLoading" :disabled="isLoading">
          <v-card-title>
            {{ $t(dialogTitle) }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">

            <v-alert color="info" text dense class="mt-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              <span @click="downlaodCanva" class="text-decoration-underline cursor-pointer">
                  {{ $t('btn.download') }}
                </span>
            </v-alert>

            <v-text-field :label="$t('field.name')"
                          :error-messages="errors.name"
                          class="required"
                          v-model="form.name">
            </v-text-field>

            <v-file-input ref="upload"
                          hide-details="auto"
                          filled rounded
                          class="required"
                          prepend-icon=""
                          :error-messages="errors.file"
                          :label="$t('field.file')"
                          @change="uploadFile" show-size chips small-chips>
              <v-icon slot="prepend-inner"
                      color="blue">
                mdi-cloud-upload
              </v-icon>
            </v-file-input>


          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :loading="isLoading"
                   type="submit"
                   depressed>
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('btn.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data: () => ({
    dialogTitle: 'database.dialog.addDatabase',
    isLoading: false,
    dialog: false,
    form: {
      file: null
    },
    errors: {},
  }),
  methods: {
    uploadFile(file) {
      this.form.file = file
    },
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "databases/store"

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key])
      })

      HTTP.post(url, formData).then(() => {
        this.$Progress.finish()
        this.$emit('refresh')
        this.isLoading = false
        this.close()
        this.$successMessage = this.$i18n.t('message.succssStore')
        this.form = {
          file: null
        }
        this.$refs.upload.reset()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    },
    downlaodCanva() {
      window.location.href = process.env.VUE_APP_FILE_URL + '/canva_opus.xlsx';
    },
  }
}
</script>